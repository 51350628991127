import React, { BaseSyntheticEvent, useEffect, useRef, useState } from "react";
import "./../index.css";
import { Form, Input, Button, Checkbox, Col, Row, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import api, { authorizeApi } from "../config/api";
import showNotification from "../utils/notify";
import {
  Link,
  Navigate,
  useNavigate,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { useAuthStore } from "../store/auth";
import HomeScreenSlider from "./HomeScreenSlider";
import LogoContainer from "./LogoContainer";

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [message, setMessage] = useState();
  const [userValue, setUserValue] = useState();
  const [resendOTP, setResendOTP] = useState(false);
  const [otpMessage, setOtpMessage] = useState(false);
  const [otp, setOTP] = useState(["", "", "", ""]);
  const inputsRef = useRef([]);

  const urlValue = window.location.pathname;
  const queryValue = window.location.search;

  const { redirect } = useSearch({
    from: "",
  });

  const search = useSearch({ from: "" });
  const isAuth = search.isAuth;

  const { login } = useAuthStore();
  const navigate = useNavigate({ from: "/" });

  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.delete("redirect");
  urlSearchParams.delete("isAuth");
  const newSearchParams = urlSearchParams.toString();

  function simpleEncrypt(plaintext) {
    if (!plaintext) {
      return "";
    }
    let ciphertext = "";
    const key = "HELLO_RAJNISH_SINGH_1_05_1997";
    const keyLength = key.length;
    for (let i = 0; i < plaintext.length; i++) {
      let char = plaintext[i];
      let keyChar = key[i % keyLength];
      let encryptedChar = char.charCodeAt(0) + keyChar.charCodeAt(0);
      ciphertext += String.fromCharCode(encryptedChar);
    }
    return btoa(ciphertext);
  }

  // function simpleDecrypt(ciphertext) {
  //   ciphertext = atob(ciphertext);
  //   let plaintext = "";
  //   const key = "HELLO_RAJNISH_SINGH_1_05_1997";
  //   const keyLength = key.length;
  //   for (let i = 0; i < ciphertext.length; i++) {
  //     let char = ciphertext[i];
  //     let keyChar = key[i % keyLength];
  //     let decryptedChar = char.charCodeAt(0) - keyChar.charCodeAt(0);
  //     plaintext += String.fromCharCode(decryptedChar);
  //   }
  //   return plaintext;
  // }

  const onFinish = async (values: any) => {
    setUserValue(values);

    if (redirect && isAuth === 1) {
      try {
        setLoading(true);
        const { data } = await api.post(`/login`, values);

        if (data?.type === "verify_phone") {
          setIsVerify(true);
          setLoading(false);
          setMessage(data?.message);
        }
        getOtpTimer(60);
        setOtpMessage(true);
        if (data?.token) {
          setLoading(false);
          window.location.href = `${redirect}?auth_token=${data?.token}&${newSearchParams}`;
        }
        if (redirect && data?.token) {
          setLoading(false);
          window.location.href = `${redirect}?auth_token=${data?.token}&${newSearchParams}`;
        }
      } catch (err) {
        setLoading(false);
      }
    } else if (redirect && isAuth !== 1) {
      showNotification("error", "This is not valid", 3);
    } else {
      try {
        setLoading(true);
        const { data } = await api.post(`/login`, values);

        if (data?.token) {
          if (data.type === "verify_phone") {
            setIsVerify(true);
            setLoading(false);
            generateLoginOTP({ phone: data?.user?.phone, type: "vphone" });
            setMessage(data.message);
            getOtpTimer(60);
            setOtpMessage(true);
          } else if (data.type === "login_success") {
            login({ user: data.user, token: data.token });
            authorizeApi(data.token);
            setLoading(false);
            navigate({ to: "/home" });
          } else {
            console.log("This is Calling...");
          }
        }

        if (urlValue && queryValue) {
          login({ user: data.user, token: data.token });
          authorizeApi(data.token);
          setLoading(false);
          navigate({ to: urlValue + queryValue });
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const handleOTPChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (value !== "" && index < otp.length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOTP(newOtp);
      inputsRef.current[index - 1].focus();
    } else if (
      e.key === "Delete" &&
      index < otp.length - 1 &&
      otp[index] === ""
    ) {
      const newOtp = [...otp];
      newOtp[index + 1] = "";
      setOTP(newOtp);
      inputsRef.current[index + 1].focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      inputsRef.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < otp.length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const onVerify = async (values: any) => {
    const verifyPayload = {
      phone: values?.phone,
      otp: otp.join(""),
    };
    try {
      delete values.password;
      setLoading(true);
      const { data } = await api.post(`/verify-phone`, verifyPayload);

      if (data?.status) {
        onFinish(userValue);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getOtpTimer = (timer) => {
    const interval = setInterval(() => {
      timer = timer - 1;
      const ele = document.getElementById("timer");
      if (ele) {
        ele.innerText = timer;
      }
      if (timer === 0) {
        // setOtpValidityMessage(false);
        setResendOTP(true);
        setOtpMessage(false);
        clearInterval(interval);
      }
    }, 1000);
  };

  const handleResendOTP = async (otpValues) => {
    const otpPayload = { phone: otpValues?.phone, type: "vphone" };

    try {
      setLoading(true);
      const { data } = await api.post(`/generate-otp`, otpPayload);
      getOtpTimer(60);
      setLoading(false);

      if (data?.status === true) {
        setOtpMessage(true);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const generateLoginOTP = async (otpValues) => {
    try {
      setLoading(true);
      const { data } = await api.post(`/generate-otp`, otpValues);
      getOtpTimer(60);
      setLoading(false);

      if (data?.status === true) {
        setOtpMessage(true);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div className="user-form-wrapper">
      <Row align="middle" justify="center" style={{ height: "100%" }}>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={0}
          xs={0}
          style={{ backgroundColor: "#EEF6FF" }}
          className=" wh-100"
        >
          <HomeScreenSlider />
        </Col>

        {isVerify ? (
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className="bg-white wh-100"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="responsive-card-width">
              <LogoContainer />
              <Typography.Title level={4}>Phone Verification</Typography.Title>
              <Typography.Title level={5}>
                {message}. Enter 4 digit OTP that you have received.
              </Typography.Title>
              <Form
                name="verify_form"
                initialValues={{
                  remember: true,
                }}
                size="large"
                onFinish={onVerify}
                layout="vertical"
                style={{ height: "100%" }}
              >
                <Form.Item
                  hidden
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Phone Number!",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Enter Valid Phone Number",
                    },
                  ]}
                  label="Phone Number"
                  initialValue={userValue?.phone}
                >
                  <Input
                    disabled={true}
                    maxLength={10}
                    onInput={(e: BaseSyntheticEvent) =>
                      (e.target.value = e.target.value.slice(0, 10))
                    }
                    placeholder="Phone"
                    type="number"
                  />
                </Form.Item>

                <Form.Item
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter OTP in required field!",
                    },
                  ]}
                >
                  <div style={{ textAlign: "center" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {otp.map((digit, index) => (
                        <Input
                          key={index}
                          ref={(el) => (inputsRef.current[index] = el)}
                          style={{
                            width: "3em",
                            height: "3em",
                            marginRight: "1.5em",
                            textAlign: "center",
                          }}
                          maxLength={1}
                          value={digit}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const numericValue = inputValue.replace(/\D/g, "");
                            handleOTPChange(index, numericValue);
                          }}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                        />
                      ))}
                    </div>
                  </div>
                </Form.Item>

                {otpMessage && (
                  <p
                    style={{
                      color: "red",
                      textAlign: "center",
                      fontSize: "1.2em",
                    }}
                  >
                    <span id="timer"></span>
                  </p>
                )}

                <Form.Item>
                  <Button
                    loading={loading}
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                    className="user-form-button"
                    size="large"
                  >
                    Verify
                  </Button>
                </Form.Item>
              </Form>
              {resendOTP && (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "1.2em",
                    color: "#7B8389",
                  }}
                >
                  If you didn’t receive OTP!{" "}
                  <span
                    style={{
                      color: "#F2451C",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleResendOTP(userValue);
                      setResendOTP(false);
                    }}
                  >
                    Resend
                  </span>
                </p>
              )}
            </div>
          </Col>
        ) : (
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className="bg-white wh-100"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="responsive-card-width">
              {/* <Row align={"middle"} style={{ marginBottom: "6%" }}>
                <Col span={5}>
                  <Image
                    src="https://kgs.nyc3.cdn.digitaloceanspaces.com/uploads/z5TV2y7lbudA4wI0C2sKlsBkcMwHv27uFWP0a5j4.png"
                    width={"5em"}
                    height={"5em"}
                    preview={false}
                  />
                </Col>
                <Col span={19}>
                  
                </Col>
              </Row> */}

              <LogoContainer />
              <Typography.Title level={4}>Welcome Back!</Typography.Title>
              <Typography.Paragraph>
                Please login to access your account.
              </Typography.Paragraph>
              <Form
                name="login_form"
                initialValues={{
                  remember: true,
                }}
                size="large"
                onFinish={onFinish}
                layout="vertical"
                style={{ height: "100%" }}
              >
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Phone!",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Enter Valid Phone Number",
                    },
                  ]}
                  label="Phone Number"
                >
                  <Input
                    maxLength={10}
                    onInput={(e: BaseSyntheticEvent) =>
                      (e.target.value = e.target.value.slice(0, 10))
                    }
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Phone"
                    type="number"
                    onKeyDown={(e) => {
                      if (
                        e.key === "e" ||
                        e.key === "ArrowUp" ||
                        e.key === "ArrowDown"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>

                  <div style={{ float: "right" }}>
                    <Link to="/forget-password">Forget Password?</Link>
                  </div>
                </Form.Item>

                <Form.Item>
                  <Button
                    loading={loading}
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                    className="user-form-button"
                    size="large"
                  >
                    Log in
                  </Button>
                </Form.Item>
              </Form>
              <div className="text-center">
                Don't have an Account?<Link to="/register"> Sign Up</Link>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Login;
