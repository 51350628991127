import React, { useEffect } from "react";
import { Button, Col, Row, Tag } from "antd";
import styled from "styled-components";
import useQuizStore from "../../../store/quizStore";
import { IQuestion } from "../../../interfaces/quiz";
import ChangeLanguage, { LANG } from "./LanguageConvert";
import { useTimerStore } from "../../../store/timer";

interface IProps {
  item: IQuestion;
  setLanguage: React.Dispatch<React.SetStateAction<LANG>>;
  language: LANG;
  onCompleteTimer?: () => void;
  onSectionCompleteTimer?: () => void;
}

export const QuizHeader = ({
  item,
  setLanguage,
  language,
  onCompleteTimer,
  onSectionCompleteTimer,
}: IProps) => {
  const { updateBookmark, status, quiz } = useQuizStore((state) => state);
  const currentStatus = status[item.id];

  const { activeTabId } = useQuizStore();

  const currentSection = quiz?.sections?.filter(
    (item) => item?.id === activeTabId
  )?.[0]?.meta;

  const initTime =
    (currentSection?.is_timer && currentSection?.timer) ||
    (quiz?.meta.is_timer && quiz?.meta?.timer);

  return (
    <Row
      style={{
        height: "10%",
        width: "100%",
      }}
    >
      <Col lg={12} xl={12} md={12} sm={8} xs={8}>
        <GrayButton
          style={{
            marginLeft: "1%",
          }}
        >
          <strong>
            Question: {"  "}
            {currentStatus.position}
          </strong>
        </GrayButton>
        <GrayButton onClick={() => updateBookmark(item?.id)}>
          <i
            className={
              currentStatus?.bookmark
                ? "bx bxs-bookmarks fs-5"
                : "bx bx-bookmarks fs-5"
            }
            style={{ cursor: "pointer" }}
          ></i>
        </GrayButton>
      </Col>
      {quiz?.meta.is_timer && (
        <Time
          handleComplete={
            quiz?.meta?.is_timer ? onCompleteTimer! : onSectionCompleteTimer!
          }
          initialTime={quiz?.meta?.is_timer ? quiz.meta.timer : initTime}
        />
      )}
      <ChangeLanguage setLanguage={setLanguage} language={language} />
    </Row>
  );
};

type TTime = { hours?: number; minutes: number; seconds: number };
type TTimeProps = {
  initialTime: TTime;
  handleComplete: () => void;
  isSmallScreen?: boolean;
  scalingFactor?: number;
};
export const Time = ({
  initialTime,
  handleComplete,
  isSmallScreen,
  scalingFactor,
}: TTimeProps) => {
  const { timer, setInitialTime, updateTimer, resetTimer } = useTimerStore();

  useEffect(() => {
    if (!timer) {
      setInitialTime(initialTime);
    }

    const intervalId = setInterval(() => {
      const success = updateTimer();
      if (!success) {
        clearInterval(intervalId);
        resetTimer();
        handleComplete();
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [initialTime]);

  const formatTime = (value) =>
    value < 10 && value !== "00" ? `0${value}` : value;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      <p
        style={{
          paddingRight: "0.5em",
          margin: "0%",
          color: "#2E1991",
          fontSize: isSmallScreen ? "1em" : "1.2em",
          fontWeight: "500",
          width: "max-content",
        }}
      >
        Time Left
      </p>
      <Button
        size={
          isSmallScreen ? "middle" : scalingFactor! >= 1.5 ? "middle" : "large"
        }
        style={{ fontWeight: "bold", color: "#2E1991" }}
      >
        {formatTime(timer?.hours)} : {formatTime(timer?.minutes)} :{" "}
        {formatTime(timer?.seconds)}
      </Button>
    </div>
  );
};

const GrayButton = styled(Tag)`
  background-color: #ebeeff;
  margin-left: 5px;
  border: none;
  font-size: 1em;
  padding: 1% 3% 1% 3%;
`;
