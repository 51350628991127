export default function BlankBookmarkedIcon(){
    return (
        <svg
        width="16"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5376 16.9975C15.249 16.9968 14.9634 16.9442 14.6973 16.8427C14.4312 16.7412 14.1899 16.5928 13.9874 16.4062L9.18937 12.076L4.39133 16.409C4.08319 16.6928 3.688 16.8858 3.25729 16.9627C2.82659 17.0396 2.38033 16.997 1.97671 16.8403C1.56909 16.6915 1.22038 16.4345 0.976115 16.103C0.731854 15.7714 0.603397 15.3807 0.607518 14.9819V3.54115C0.607518 2.60198 1.0185 1.70127 1.75005 1.03718C2.4816 0.373085 3.47379 0 4.50836 0L13.8704 0C14.3826 0 14.8899 0.0915946 15.3632 0.269554C15.8364 0.447514 16.2665 0.708353 16.6287 1.03718C16.9909 1.36601 17.2783 1.75638 17.4743 2.18601C17.6703 2.61565 17.7712 3.07612 17.7712 3.54115V14.9819C17.7756 15.3804 17.6476 15.7709 17.4039 16.1024C17.1602 16.4339 16.8122 16.691 16.4051 16.8403C16.1304 16.9446 15.8354 16.9981 15.5376 16.9975ZM4.50836 1.41646C3.88762 1.41646 3.2923 1.64031 2.85337 2.03877C2.41444 2.43723 2.16785 2.97765 2.16785 3.54115V14.9819C2.16757 15.0999 2.20583 15.2154 2.27778 15.3137C2.34974 15.412 2.45217 15.4887 2.57213 15.5341C2.6921 15.5796 2.82422 15.5918 2.9518 15.5691C3.07938 15.5465 3.19671 15.49 3.28896 15.4069L8.64325 10.576C8.78943 10.4441 8.98716 10.3701 9.19327 10.3701C9.39938 10.3701 9.59711 10.4441 9.74329 10.576L15.0913 15.4054C15.1836 15.4886 15.3009 15.5451 15.4285 15.5677C15.5561 15.5904 15.6882 15.5782 15.8082 15.5327C15.9281 15.4873 16.0306 15.4105 16.1025 15.3122C16.1745 15.214 16.2127 15.0985 16.2124 14.9805V3.54115C16.2124 2.97765 15.9659 2.43723 15.5269 2.03877C15.088 1.64031 14.4927 1.41646 13.8719 1.41646H4.50836Z"
          fill="#2E3192"
        />
      </svg>
    )
}