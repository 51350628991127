import { Button, Row } from "antd";
import React from "react";
import useQuizStore from "../../../../store/quizStore";

const QuizSectionHeader = ({
  quiz,
  setActiveTab,
  setCurrentSectionQues,
  onScroll,
  setCurrentSectionTitle,
  scalingFactor,
}: any) => {
  const { activeTabId } = useQuizStore();

  const currentSection = quiz?.sections?.filter(
    (item) => item?.id === activeTabId
  )?.[0]?.meta;

  // console.log({ currentSection });
  // console.log({
  //   a: quiz?.sections?.filter((item) => item?.id === activeTabId),
  // });

  return (
    <>
      <Row
        style={{
          width: "100%",
          height: "100%",
          paddingLeft: "2em",
          paddingRight: "2em",
        }}
        align={"middle"}
      >
        <div
          style={{
            width: "100%",
            overflowX: "scroll",
            display: "flex",
            scrollBehavior: "smooth",
            background: "#F5F6FF",
            borderRadius: "0.3em",
          }}
          className="hideScrollbar"
        >
          {quiz?.sections?.map((i) => (
            <Button
              key={i?.id}
              size={scalingFactor >= 1.5 ? "middle" : "large"}
              style={{
                background: i?.id === activeTabId ? "#fff" : "transparent",
                color: i?.id === activeTabId ? "#111827" : "#9CA3AF",
                fontWeight: i?.id === activeTabId ? "500" : "600",
                border: "none",
                margin: "0.5em",
              }}
              onClick={() => {
                setActiveTab(i?.id);
                setCurrentSectionQues(i?.questions);
                setCurrentSectionTitle(i?.title);
                onScroll(1);
              }}
              disabled={
                currentSection?.is_timer &&
                quiz?.sections?.filter((item) => item?.id !== activeTabId)
              }
            >
              {i?.title}
            </Button>
          ))}
        </div>
      </Row>
    </>
  );
};

export default QuizSectionHeader;
