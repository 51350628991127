import React, { useEffect, useState } from "react";
import { Link, useParams } from "@tanstack/react-router";
import { Button, Card, Col, Image, Row } from "antd";
import parse from "html-react-parser";
import Loader from "../../component/loader";
import useApi from "../../hooks/useApi";
import { IQuiz } from "../../interfaces/quiz";
import useMount from "../../hooks/useMount";
import useQuizStore from "../../store/quizStore";
import { useTimerStore } from "../../store/timer";
const kgsLogo =
  "https://kgs.nyc3.cdn.digitaloceanspaces.com/uploads/hbC0ocuRD41Btvm2JRqK9gkjCxMPkH2YIXShLG5Q.png";
const svg =
  "https://kgs.nyc3.cdn.digitaloceanspaces.com/uploads/gZ8c21yGzv9cq46poHIw3OI7e6dmcSab1SSglToM.png";
const StartQuiz = () => {
  const { qId, quizzesType } = useParams({ from: "" });
  const { data, load, isLoading, error } = useApi<IQuiz>(
    `/user/quizzes/${qId}?with_answer=true`
  );
  const { resetTimer } = useTimerStore();
  const [disabled, setDisabled] = useState(false);
  useMount(() => {
    if (data) {
      if (data?.status == false) {
        setDisabled(true);
      } else setDisabled(false);
    }
  }, [data]);

  const { setQuiz, setSectionQuiz } = useQuizStore();
  useMount(() => {
    load();
  });

  useMount(() => {
    if (data && data?.is_sectional === 0) {
      setQuiz(data);
    }
    if (data && data?.is_sectional === 1) {
      setSectionQuiz(data);
    }
  }, [data]);

  useEffect(() => {
    resetTimer();
  }, []);

  const marking = data?.meta?.marking;

  return (
    <Row className="quizSVG" style={{ height: "100%", padding: "2%" }}>
      <Col xs={0} sm={0} md={12} lg={12} xl={12}>
        <Row style={{ height: "100%" }}>
          <Col span={4}>
            <Image src={kgsLogo} width={"60%"} preview={false} />
          </Col>
          <Col
            span={15}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={svg} width={"80%"} preview={false} />
          </Col>
        </Row>
      </Col>

      <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ height: "100%" }}>
        <Card
          className="hideScroll"
          style={{ height: "100%", overflow: "auto" }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <Row>
              <Col span={24}>
                <section>
                  <div className="quizDetails">
                    <h1>{data?.title}</h1>
                    <strong>Instruction:</strong>
                    {data && (
                      <ul>
                        {data?.questions?.length && (
                          <li>
                            Total number of questions:{" "}
                            <b>{data?.questions?.length} Questions</b>
                          </li>
                        )}
                        <li>
                          Each question carries :{" "}
                          {data?.meta?.isMarking ? (
                            <ol>
                              <li>
                                Single Choice Question :{" "}
                                <b>{marking.scq.correct} Mark</b>
                              </li>
                              <li>
                                Multi Choice Question :{" "}
                                <b>{marking.msq.correct} Mark</b>
                              </li>
                              <li>
                                Boolean Question :{" "}
                                <b>{marking.bq.correct} Mark</b>
                              </li>
                              <li>
                                Integer Base Question :{" "}
                                <b>{marking.ibq.correct} Mark</b>
                              </li>
                            </ol>
                          ) : (
                            <b>{data?.meta?.correct_mark || "N/A"} mark</b>
                          )}
                        </li>
                        <li>
                          Negative marks of Each Questions:{" "}
                          {data?.meta?.isMarking ? (
                            <ol>
                              <li>
                                Single Choice Question :{" "}
                                <b>{marking.scq.incorrect_mark} Mark</b>
                              </li>
                              <li>
                                Multi Choice Question :{" "}
                                <b>{marking.msq.incorrect_mark} Mark</b>
                              </li>
                              <li>
                                Boolean Question :{" "}
                                <b>{marking.bq.incorrect_mark} Mark</b>
                              </li>
                              <li>
                                Integer Base Question :{" "}
                                <b>{marking.ibq.incorrect_mark} Mark</b>
                              </li>
                            </ol>
                          ) : (
                            <b>{data.meta?.incorrect_mark || "N/A"} mark</b>
                          )}
                        </li>
                        {/* {data?.meta?.leaderboard_on === "with_date" && (
                          <li>
                            Leaderboard will be show on :{" "}
                            <b>
                              {moment(data?.meta?.lpublished_at).format(
                                "DD-MM-YYYY"
                              )}
                            </b>
                          </li>
                        )} */}
                        {data?.meta?.is_timer && (
                          <>
                            {/* <li>
                              Leaderboard will be show on :{" "}
                              <b>
                                {moment(data?.meta?.lpublished_at).format(
                                  "DD-MM-YYYY"
                                )}
                              </b>
                            </li> */}
                            <li>
                              Time allotted :{" "}
                              <b>
                                {data.meta?.timer?.minutes}:
                                {data.meta?.timer?.seconds} min
                              </b>
                            </li>
                          </>
                        )}
                      </ul>
                    )}
                    <section>
                      <strong>General Instruction:</strong>
                      <p style={{ fontWeight: "bold" }}>
                        <ol>
                          <li>
                            The Question Palette displayed on the right side of
                            screen will show the status of each question using
                            one of the following symbols:
                          </li>
                        </ol>
                      </p>
                      <div>
                        <Row
                          style={{
                            paddingLeft: "2rem",
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <p className="instructions">
                            <button
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                borderRadius: "2px",
                                boxShadow: "0px 2px 2px  gray",
                                border: "none",
                                padding: "6px",
                                background: "white",
                              }}
                            ></button>
                            <b> &nbsp;You have not visited the question yet.</b>
                          </p>
                        </Row>
                        <Row style={{ paddingLeft: "2rem", display: "flex" }}>
                          <p className="instructions">
                            {" "}
                            <button
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                borderRadius: "2px",
                                boxShadow: "0px 2px 2px  gray",
                                border: "none",
                                padding: "6px",
                              }}
                            ></button>
                            <b>
                              &nbsp;{""} You have not answered the question.
                            </b>
                          </p>
                        </Row>
                        <Row style={{ paddingLeft: "2rem", display: "flex" }}>
                          <p className="instructions">
                            <button
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                borderRadius: "2px",
                                boxShadow: "0px 2px 2px  gray",
                                border: "none",
                                padding: "6px",
                                background: "#00B96B",
                              }}
                            ></button>
                            <b>&nbsp; You have answered the question.</b>
                          </p>
                        </Row>
                        <Row style={{ paddingLeft: "2rem", display: "flex" }}>
                          <p className="instructions">
                            <button
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                borderRadius: "2px",
                                boxShadow: "0px 2px 2px  gray",
                                border: "none",
                                padding: "6px",
                                background: "#FFA500",
                              }}
                            ></button>
                            <b>
                              &nbsp; You have NOT answered the question, but
                              have marked the question for review.
                            </b>
                          </p>
                        </Row>
                        <b>
                          The Mark for Review status for a question simply
                          indicates that you would like to look at the question
                          again. If a question is answered, but marked for
                          review , then the answer will be considered for
                          evaluation unless the status is modified by the
                          indicates.
                        </b>
                      </div>
                    </section>
                    {data?.content && parse(data.content)}
                    <Link to={`/${quizzesType}/${data?.id}/quiz`}>
                      <Button
                        disabled={disabled}
                        size="large"
                        style={{
                          backgroundColor:
                            disabled === false ? "#2E3192" : "#DC143C",
                          color: "white",
                          marginTop: "2%",
                        }}
                      >
                        {disabled === false
                          ? "Start"
                          : " You've reached the maximum number of attempts"}
                      </Button>
                    </Link>
                  </div>
                </section>
              </Col>
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default StartQuiz;
