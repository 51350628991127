import React from "react";
import { Col, Dropdown, Menu, Tag } from "antd";
import { DownOutlined } from "@ant-design/icons";
import styled from "styled-components";

const items = [
  {
    key: "1",
    value: "en",
    label: "English",
  },
  {
    key: "2",
    value: "hi",
    label: "Hindi",
  },
];

export enum LANG {
  Hi = "hi",
  En = "en",
}

const ChangeLanguage = ({ language, setLanguage }) => {
  const menu = (
    <Menu onClick={(e) => setLanguage(e.key)}>
      {items.map((item) => (
        <Menu.Item key={item.value}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <LangButton
        onClick={() => setLanguage(LANG.En)}
        $active={language === LANG.En}
      >
        English
      </LangButton>
      <LangButton
        onClick={() => setLanguage(LANG.Hi)}
        $active={language === LANG.Hi}
      >
        Hindi
      </LangButton>
    </>
    // <Col sm={4} xs={4} style={{ display: "flex", justifyContent: "end" }}>
    //   <p style={{ fontWeight: "bold" }}>
    //     <span className="hideLanguageText">View in </span>
    //     <Dropdown overlay={menu} trigger={["click"]}>
    //       <Tag onClick={(e) => e.preventDefault()}>
    //         {language === LANG.En ? "English" : "Hindi"}
    //         <DownOutlined style={{ marginLeft: "10px" }} />
    //       </Tag>
    //     </Dropdown>
    //   </p>

    // </Col>
  );
};

const LangButton = styled(Tag)<{ $active?: boolean }>`
  border-radius: 30px;
  cursor: pointer;
  background-color: ${(props) => (props.$active ? "#F5F6FF" : "")};
  border-color: ${(props) => (props.$active ? "#2E3192" : "")};
  color: ${(props) => (props.$active ? "#191919" : "")};
  // height: 3.5vh;
  // width: 7.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
`;

export default ChangeLanguage;
