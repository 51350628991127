import { Button, Col, Row, Tag } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { IStatus } from "../../../../interfaces/quiz";
import useQuizStore from "../../../../store/quizStore";

const SectionIndex = ({
  handleScroll,
  title,
  pageId,
  onClickSubmit,
  isSmallScreen,
  scalingFactor,
}) => {
  const { quiz, status, currentSectionQues } = useQuizStore((status) => status);
  const [questions, setQuestions] = useState(quiz?.questions);

  const [isQuizSubmit, setIsQuizSubmit] = useState(false);
  const handleQuestion = (position: number) => {
    handleScroll(position);
  };

  const handleModal = (val: string) => {
    setIsQuizSubmit(!isQuizSubmit);
  };

  const getColorClass = (status: IStatus) => {
    if (status?.bookmark) return "question-bookmarked";
    if (status?.attempt?.length !== 0) return "question-attempted";
    if (status?.visited) return "currentQuestion";
    return "question-default";
  };

  // Extract IDs from currentSectionQues
  const currentIds = currentSectionQues?.map((question) =>
    question.id.toString()
  );

  // Filter the status object
  const filteredStatus = Object.fromEntries(
    Object.entries(status)?.filter(([key]) => currentIds?.includes(key))
  );

  const visitedCount = Object.values(filteredStatus)?.filter(
    (item) => item.visited
  ).length;

  const bookmarkedCount = Object.values(filteredStatus)?.filter(
    (item) => item?.bookmark
  ).length;

  const attemptedCount = Object.values(filteredStatus)?.filter((item) => {
    if (Array.isArray(item.attempt)) {
      return item.attempt.length > 0;
    }
    return !!item.attempt;
  });

  const nonSecAttmept = Object.values(status)?.filter((item) => {
    if (Array.isArray(item.attempt)) {
      return item.attempt.length > 0;
    }
    return !!item.attempt;
  });

  const nonSecBookmarkedCount = Object.values(status)?.filter(
    (item) => item?.bookmark
  ).length;

  const nonSecVisitedCount = Object.values(status)?.filter(
    (item) => item.visited
  ).length;

  return (
    <div style={{ height: "100%" }}>
      <div style={{ height: "92%" }}>
        <div style={{ padding: "3.5%" }}>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "1.2em",
              color: "#5B5B5B",
              marginBottom: "0.5%",
            }}
          >
            Select which Question number you want to attempt.
          </p>
          {title && (
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.2em",
                color: "#5B5B5B",
              }}
            >
              Section- {title}
            </p>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Tag
            style={{
              border: "1px solid #00C95C",
              paddingTop: "0.3em",
              paddingBottom: "0.3em",
              paddingLeft: "1.5em",
              paddingRight: "1.5em",
              margin: "0.5em",
              fontSize: "1.1em",
              borderRadius: "0.6em",
            }}
            className="question-attempted"
          >
            <b>{attemptedCount?.length || nonSecAttmept?.length} Attempted</b>
          </Tag>

          <Tag
            style={{
              border: "1px solid #F68D2B",
              paddingTop: "0.3em",
              paddingBottom: "0.3em",
              paddingLeft: "1.5em",
              paddingRight: "1.5em",
              margin: "0.5em",
              fontSize: "1.1em",
              borderRadius: "0.6em",
            }}
            className="question-bookmarked"
          >
            <b>{bookmarkedCount || nonSecBookmarkedCount} Bookmarked</b>
          </Tag>

          <Tag
            style={{
              border: "1px solid #A0A0A0",
              paddingTop: "0.3em",
              paddingBottom: "0.3em",
              paddingLeft: "1.5em",
              paddingRight: "1.5em",
              margin: "0.5em",
              fontSize: "1.1em",
              borderRadius: "0.6em",
            }}
            className="question-default"
          >
            <b>
              {(quiz?.is_sectional === 1 ? currentSectionQues : questions)
                ?.length - (visitedCount || nonSecVisitedCount)}{" "}
              Not Visited
            </b>
          </Tag>

          <Tag
            style={{
              border: "1px solid #909090",
              paddingTop: "0.3em",
              paddingBottom: "0.3em",
              paddingLeft: "1.5em",
              paddingRight: "1.5em",
              margin: "0.5em",
              fontSize: "1.1em",
              borderRadius: "0.6em",
            }}
            className="currentQuestion"
          >
            <b>
              {(quiz?.is_sectional === 1 ? currentSectionQues : questions)
                ?.length -
                (attemptedCount?.length || nonSecAttmept?.length)}{" "}
              Not Answered
            </b>
          </Tag>
        </div>

        <div style={{ height: "68%", overflowY: "scroll" }}>
          <Row style={{ paddingBottom: "5%", paddingTop: "3%" }}>
            {(quiz?.is_sectional === 1 ? currentSectionQues : questions)?.map(
              (item, index) => {
                const currentStatus: IStatus = status[item.id];
                const className = getColorClass(currentStatus);
                return (
                  <Col xl={4} lg={6} md={6} sm={4} xs={6}>
                    <IndexBtn
                      className={
                        (index + 1 === pageId && "activeQues") || className
                      }
                      onClick={() => handleQuestion(index + 1)}
                    >
                      {index + 1}
                    </IndexBtn>
                  </Col>
                );
              }
            )}
          </Row>
        </div>
      </div>

      <div
        style={{
          height: "6%",
          display: "flex",
          alignItems: "center",
          paddingLeft: "2em",
          paddingRight: "2em",
        }}
      >
        <Button
          style={{
            fontWeight: 800,
            paddingRight: "2em",
            width: "100%",
            background: "#2E3192",
            color: "white",
          }}
          onClick={() => onClickSubmit()}
          size={scalingFactor >= 1.5 ? "middle" : "large"}
        >
          Submit
        </Button>
      </div>
    </div>
    // <div
    //   style={{
    //     width: "100%",
    //     height: "100%",
    //     position: "relative",
    //     // border: "20px solid gray",
    //   }}
    // >
    // {/* <div
    //   style={{
    //     width: "100%",
    //     height: scalingFactor ? "15%" : "11%",
    //     paddingLeft: "1.3em",
    //     paddingTop: "1em",
    //   }}
    // >

    // </div>

    // <div
    //   style={{
    //     width: "100%",
    //     height: scalingFactor ? "13%" : "10%",
    // display: "flex",
    // flexWrap: "wrap",
    // alignItems: "center",
    // justifyContent: "flex-start",
    //   }}
    // >

    // </div>

    // <div
    //   style={{
    //     width: "100%",
    //     // height: scalingFactor ? "62%" : "77%",
    //     overflowY: "scroll",
    //   }}
    // >

    // </div>

    // <div
    //   style={{
    //     position: "absolute",
    //     width: "100%",
    //     display: "flex",
    //     alignItems: "center",
    //     paddingLeft: "1.2em",
    //     paddingRight: "1.2em",
    //   }}
    // >

    // </div> */}
  );
};

const IndexBtn = styled(Button)`
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 2px;
    box-shadow: 0px 1px 2px gray;
    margin-left: 0.8rem;
    margin-top: 0.8rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: '#2e3192';
    font-size: 1.4
    font-weight: 800
`;

export default SectionIndex;
