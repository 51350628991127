import React from "react";
import { Row, Tag } from "antd";
import styled from "styled-components";
import useQuizStore from "../../../../store/quizStore";
import ChangeLanguage from "../../../../component/quiz/attempt/LanguageConvert";
import FilledBookmarkedIcon from "../../../../component/icons/result/FilledBookmarked";
import BlankBookmarkedIcon from "../../../../component/icons/result/BlankBookmarked";

const QueIndexHeader = ({
  item,
  pageId,
  language,
  setLanguage,
  is_sectional,
  isSmallScreen,
}: any) => {
  const { updateBookmark, status } = useQuizStore((state) => state);
  const currentStatus = status[item.id];

  return (
    <>
      <Row
        style={{
          height: "100%",
          width: "100%",
          paddingLeft: "2em",
          paddingRight: "2em",
          paddingTop: "5px",
        }}
        justify={"space-between"}
        align={"middle"}
      >
        <div style={{ display: "flex" }}>
          <GrayButton
            style={{
              marginLeft: "1%",
            }}
          >
            Question {pageId}
          </GrayButton>
          <BookMarkButton onClick={() => updateBookmark(item?.id)}>
            {!currentStatus?.bookmark ? (
              <BlankBookmarkedIcon />
            ) : (
              <FilledBookmarkedIcon />
            )}
          </BookMarkButton>
        </div>

        <Row
          style={{
            height: "100%",
          }}
          align={"middle"}
          justify={"end"}
        >
          <ChangeLanguage setLanguage={setLanguage} language={language} />
        </Row>
      </Row>
    </>
  );
};
const GrayButton = styled(Tag)`
  background-color: #ebeeff;
  border: none;
  font-size: 1.2em;
  font-weight: 600;
  // padding: 1.5% 4% 1.5% 4%;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.6em;
  padding-bottom: 0.3em;
`;
const BookMarkButton = styled(Tag)`
  background-color: #ebeeff;
  border: none;
  // padding: 1.25%;
  cursor: pointer;
  padding-left: 0.6em;
  padding-right: 0.6em;
  padding-top: 0.8em;
  padding-bottom: 0.3em;
`;
export default QueIndexHeader;
