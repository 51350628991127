import { Button, Row } from "antd";
import React from "react";
import { Time } from "../../../../component/quiz/attempt/quizHeader";
import { MenuOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";

const QuizNameHeader = ({
  quiz,
  onQuit,
  onCompleteTimer,
  collapsed,
  setCollapsed,
  scalingFactor,
  activeTabId,
  setActiveTabId,
  onSectionCompleteTimer,
}: any) => {
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const currentSection = quiz?.sections?.filter(
    (item) => item?.id === activeTabId
  )?.[0]?.meta;

  const initTime =
    (currentSection?.is_timer && currentSection?.timer) ||
    (quiz?.meta.is_timer && quiz?.meta?.timer);

  return (
    <Row
      align={"middle"}
      style={{
        height: "100%",
        background: "white",
        boxShadow: "0px 4px 4px 0px #00000040",
        // padding: "1.25em",
        flexFlow: "nowrap",
        borderRadius: "0.8em 0.8em 0em 0em",
        paddingLeft: "2em",
        paddingRight: "2em",
      }}
      justify={"space-between"}
    >
      {/* Title */}
      <p
        style={{
          fontSize: "1.4em",
          fontWeight: "bold",
          margin: "0%",
        }}
        className="card-content2"
      >
        {quiz?.title}
      </p>

      <div
        style={{
          gap: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* Timer Button */}
        {!isSmallScreen && initTime && (
          <Time
            handleComplete={
              quiz?.meta?.is_timer ? onCompleteTimer : onSectionCompleteTimer
            }
            initialTime={initTime}
            scalingFactor={scalingFactor}
          />
        )}

        {/* Quit Button */}
        {!isSmallScreen && (
          <Button
            style={{ fontWeight: "bold", color: "#2E1991" }}
            onClick={() => onQuit()}
            size={scalingFactor >= 1.5 ? "middle" : "large"}
          >
            Quit Test
          </Button>
        )}

        {/* menu button */}
        {collapsed && (
          <MenuOutlined
            style={{
              color: "#161976",
              fontSize: "2em",
              margin: "0.5%",
            }}
            onClick={() => setCollapsed(!collapsed)}
          />
        )}
      </div>
    </Row>
  );
};

export default QuizNameHeader;
